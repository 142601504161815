import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faYoutube} from '@fortawesome/free-brands-svg-icons';
import { faBars, faClock, faSearch, faUser} from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import './style.css'
import { useEffect, useState } from 'react';
import Icons from '../../Icons.jsx';
import Loader from '../Admin/Loader';
import Search from '../Search/Search';
import Logo from '../../assets/ads/logo.svg';



export default function Header () {
  /*Weather URI*/
  const location = 'Neuquén'
  const URI = `https://api.openweathermap.org/data/2.5/weather?q=${location}&lang=es&units=metric&appid=${process.env.REACT_APP_API_KEY}`
  const [icons, setIcons] = useState('');
  const [values, setValues] = useState('');
  const [search, setSearch] = useState(false);
  const [changeSearch, setChangeSearch] = useState('');

  const [open, setOpen] = useState(false);

  const [menu, setMenu] = useState(false)
  const [changeNavbar, setChangeNavbar] = useState(false);
  const [overFlow ,setOverFlow] = useState("");

  const navigate = useNavigate();

  /* <====== Quitar el overflow al abrir el menu responsive =====> */
  const [searchVisible, setSearchVisible] = useState(false);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    let handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    } ;

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const handleMenu = () => {
    // menu ? setOverFlow(document.querySelector("body").className = 'overflow-auto') : setOverFlow(document.querySelector("body").className = 'overflow-hidden') ;
    setMenu(!menu);
  }

  const handleChangeNavbar = () => {
    if(window.scrollY >= 97.35000610351562){ setChangeNavbar(true);}
    else{ setChangeNavbar(false);}
  };

  window.addEventListener('scroll', handleChangeNavbar);

  /*========== Weather ==========*/
  const LOCATION = window.location.href;
  const getData = async () => {
    await fetch(URI)
      .then(response => {return response.json()})
      .then(data => { setValues(data); setIcons(data.weather[0].main)})
      .catch(error => { console.log(error) })
    }

  /*Busqueda en Desktop*/
  const handleSearch = (e) => { e.preventDefault(); setSearch(!search); }

  /*Busqueda Responsive*/
  const handleResponsiveSearch = (e) => { e.preventDefault(); setChangeSearch(e.target.value); }

  const handleChange = (value) => { setInput(value); }

  const handleNavigate = () => {
    navigate(`/buscar/${changeSearch.replaceAll(' ', '+')}`);
  };

  useEffect(() => { getData(); /*showButton();*/ }, []);

  /*========== Weather ==========*/

  /*========== User Settings ==========*/
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const capitalize =  userInfo ? userInfo.userData.username.replace(/^\w/, c => c.toUpperCase()) : null;
  /*========== User Settings ==========*/

  /*========== Date ==========*/
  /*----- Arrs -----*/
    const week = [ { day: "Domingo", date: 0}, { day: "Lunes", date: 1 }, { day: "Martes", date: 2 }, { day: "Miercoles", date: 3 }, { day: "Jueves", date: 4 } ,{ day: "Viernes", date: 5 }, { day: "Sábado", date: 6 } ];
    const calendar = [ {name: "Enero", date: 0}, { name: "Febrero", date: 1 }, { name: "Marzo", date: 2 }, { name: "Abril", date: 3 }, { name: "Mayo", date: 4 }, { name: "Junio", date: 5 }, { name: "Julio", date: 6 }, { name: "Agosto", date: 7 }, { name: "Septiembre", date: 8 }, { name: "Octubre", date: 9 }, { name: "Noviembre", date: 10 }, { name: "Diciembre", date: 11 }]

    /*----- Fn -----*/  
    const current = new Date();
    /*Days*/
    const GETDAY = () => { return current.getDay() };
    const dayFiltered = week.map((e) => e).filter((e) => e.date === GETDAY());
    /*Months*/
    const GETMONTH = () => { return current.getMonth() };
    const monthFiltered = calendar.map((e) => e).filter((e) => e.date === GETMONTH())
  /*========== Date ==========*/
  
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return(
  <section className={menu ? 'boder h-max fixed w-100 z-20 bg-white-500 top-0' : 'h-max fixed w-100 z-20 bg-white top-0'}>
    <header className='bg-white'>

    <div className={changeNavbar == true ? 'hidden ' : 'block' && menu ? 'hidden' : 'block'}>

      <div id="rainbow-line"></div>

    <div className="flex justify-between" id="container">
      <p className={isDesktop ? "p-1 text-start text-md w-auto h-max text-black" : 'text-sm'}><FontAwesomeIcon className='text-neutral-400' icon={faClock}/> {dayFiltered[0].day}, {current.getDate()} de {monthFiltered[0].name.toLowerCase()} de {current.getFullYear()}</p>

      <div className='justify-center hidden m-auto lg:flex'>
          <Link to="/" className='p-2 h-100 w-100'>
            <img src={Logo} className="h-16" width="364" height={36} alt="VoxNews-Logo"/>
          </Link>
      </div>
        
      <div className=''>
        <a target="_blank" href="https://twitter.com/VoxDiario"   className='px-1 text-2xl text-cyan-300'> <FontAwesomeIcon icon={faTwitter}/> <p className='hidden'>twitter</p></a>
        <a target="_blank" href="http://www.youtube.com/Ultravox"   className="px-1 text-2xl text-red-500"> <FontAwesomeIcon icon={faYoutube}/> <p className='hidden'>youtube</p></a>
        <a target="_blank" href="https://www.instagram.com/voxdiario.ok/" className="px-1 text-2xl text-purple-600"> <FontAwesomeIcon icon={faInstagram}/> <p className='hidden'>instagram</p></a>
      </div>
    </div>
    </div>


    <section className={ menu == true && isMobile && isTablet ? 'absolute h-full w-100' : 'relative h-full w-100 transition ease-in-out delay-200 duration-200'}>
        {/* HAMBURGER POPUP OPEN MENU */}
        <section className={menu == true ? 'w-full h-full bg-[#1D1D1D] fixed flex top-8 md:top-20 justify-center overflow-y-auto' : 'hidden'}>

          <ul className='absolute h-full px-6 py-2 text-white w-100 top-16'>

            <li className='flex justify-center w-11/12 text-2xl '>
              <input
                type='text'
                placeholder='Buscar...'
                className='h-8 p-1 text-black outline-none text-md w-100 placeholder:text-black'
                onChange={handleResponsiveSearch}
              />

              <button onClick={handleNavigate}>
                <FontAwesomeIcon
                  icon={faSearch}
                  className="p-2 bg-[#604595] h-4"
                />
              </button>

            </li>
            <li className='pt-2 pb-1 font-bold text-xl border-0 border-bottom border-[#696969]'>
              <h4>Noticias</h4>
            </li>
            <li className='p-1 text-xl sm:text-md md:text-md w-98 hover:bg-[#604594]'> <Link onClick={handleMenu} className='hover:text-white' to="/Search/Poli tica"> Política </Link> </li>
            <li className='p-1 text-xl sm:text-md md:text-md w-98 hover:bg-[#604595]'> <Link onClick={handleMenu} className='hover:text-white' to="/Search/Economia"> Economía </Link> </li>
            <li className='p-1 text-xl sm:text-md md:text-md w-98 hover:bg-[#604595]'> <Link onClick={handleMenu} className='hover:text-white' to="/Search/Energia"> Energía </Link> </li>
            <li className='p-1 text-xl sm:text-md md:text-md w-98 hover:bg-[#604595]'> <Link onClick={handleMenu} className='hover:text-white' to="/Search/Sociedad"> Sociedad </Link> </li>
            <li className='p-1 text-xl sm:text-md md:text-md w-98 hover:bg-[#604595]'> <Link onClick={handleMenu} className='hover:text-white' to="/Search/Deportes"> Deportes </Link> </li>
            <li className='p-1 text-xl sm:text-md md:text-md w-98 hover:bg-[#604595]'> <Link onClick={handleMenu} className='hover:text-white' to="/Search/Policiales"> Policiales </Link> </li>
            <li className='p-1 text-xl sm:text-md md:text-md w-98 hover:bg-[#604595]'> <Link onClick={handleMenu} className='hover:text-white' to="/Search/Espectaculos"> Espectáculos </Link> </li>
            <li className='p-1 text-xl sm:text-md md:text-md w-98 hover:bg-[#604595]'> <Link onClick={handleMenu} className='hover:text-white' to="/Search/Tecnologia"> Tecnología </Link> </li>
            <li className='pt-2 pb-1 font-bold text-xl border-0 border-bottom border-[#696969]'><h4>Secciones</h4></li>
            <li className='p-1 text-xl w-98 hover:bg-purple-800'> <a className='hover:text-white' href="https://los40.voxdiario.com"> Los 40 | Música </a> </li>
            <li className='p-1 text-xl w-98 hover:bg-purple-800'> <Link className="hover:text-white" to="/Inmobiliarias"> Sector Inmobiliario </Link></li>
            <li className='pt-3 pb-1 font-bold text-xl border-0 border-bottom border-[#696969]'>
              <h4>Redes</h4>
            </li>
            <li className='p-2'>
          <a className="p-3 text-xl cursor-pointer text-cyan-200" href="https://twitter.com/VoxDiario" target="_blank">
            <FontAwesomeIcon icon={faTwitter} />
            <span className="ml-1 text-xs">Twitter</span>
          </a>
          <a className="p-3 text-xl text-red-500 cursor-pointer" href="#" target="_blank">
            <FontAwesomeIcon icon={faYoutube} />
            <span className="ml-1 text-xs">Youtube</span>
          </a>
          <a className="p-3 text-xl text-pink-500 cursor-pointer" href="https://www.instagram.com/voxdiario.ok/" target="_blank">
            <FontAwesomeIcon icon={faInstagram} />
            <span className="ml-1 text-xs">Instagram</span>
          </a>
            </li>
            <li className='flex justify-center h-32 p-8 text-3xl font-bold text-center underline underline-offset-4'>
              <Link to="/" className='text-white focus:text-red-500 active:text-blue-200'><h4>VoxDiario</h4></Link>
              <Link to="/account/login" className="pl-4 hover:text-purple-500">
                  {userInfo ? 
                    <p className='m-auto cursor-pointer hover:text-purple-500 text-md'>
                      <FontAwesomeIcon icon={faUser} className="cursor-pointer "/>
                    </p>
                  :
                  <>
                    <FontAwesomeIcon icon={faUser} className="pr-1 text-sm cursor-pointer text-md"/>
                  </>}
            </Link>
            </li>
          </ul>
        </section>
        
      <div id='' className='relative flex bg-white shadow-xl w-100 border-b-3'>
      <nav className={changeNavbar == true ? 'w-100 border-none flex m-auto justify-between md:justify-between ' : 'flex w-100 right-0 relative justify-between m-auto'} >
        {/* MENU POPUP */}
        <div id="rainbow-line" className={ changeNavbar ? 'w-100 absolute m-0 left-0' : 'hidden' && menu == true ? 'w-100 absolute m-0 left-0' : 'hidden'  }/>


        <span className={changeNavbar == true ? 'flex lg:hidden bg-white relative p-3 top-0 m-auto w-100' : 'flex lg:hidden relative p-3 m-auto w-100 bg-white'}>
          <span className='flex justify-center m-auto'>
            <a href="https://voxdiario.com">
            <img src={Logo} className="h-16" height={32} width="auto" loading="lazy"/>
            </a>
          </span>
          <button className='relative justify-end p-2' onClick={handleMenu} >
            <FontAwesomeIcon icon={faBars} className="text-3xl text-black"/>
          </button>
        </span>
        <div className='justify-center hidden m-auto lg:flex lg:w-100 md:text-sm lg:text-sm'>
          <ul className='flex'>

            <li className={ changeNavbar ? 'block' : 'hidden'}>
              <Link to="/" className='h-100 pt-0.5 flex'>
              <img src={Logo} className="h-12 m-auto" height={12} loading="lazy"/>
              </Link>
            </li>

            <li className='hidden text-sm font-medium uppercase cursor-pointer md:flex h-max'>
              <Link to="/" className='flex hover:underline decoration-purple-800 underline-offset-8 decoration-[5px]'>
                <p className={changeNavbar ? 'text-black px-2 py-4' : 'text-black px-2 py-3'}>Home</p>
              </Link>
            </li>

            <li className='hidden text-sm font-medium uppercase cursor-pointer md:flex h-max'>
              <Link to="/search/politica" className="hover:underline decoration-purple-800 underline-offset-8 decoration-[5px]">
                <p className={changeNavbar ? 'text-black px-2 py-4' : 'text-black px-2 py-3'}>Política</p>
              </Link>
            </li>

            <li className='hidden text-sm font-medium uppercase cursor-pointer md:flex h-max'>
              <Link to="/search/economia" className="hover:underline decoration-purple-800 underline-offset-8 decoration-[5px]">
                <p className={changeNavbar ? 'text-black px-2 py-4' : 'text-black px-2 py-3'}>Economía</p>
              </Link>
            </li>

            <li className='hidden text-sm font-medium uppercase cursor-pointer md:flex h-max'>
              <Link to="/search/sociedad" className="hover:underline decoration-purple-800 underline-offset-8 decoration-[5px]">
                <p className={changeNavbar ? 'text-black px-2 py-4' : 'text-black px-2 py-3'}>Sociedad</p>
              </Link>
            </li>

            <li className='hidden text-sm font-medium uppercase cursor-pointer md:flex h-max'>
              <Link to="/search/deportes" className="hover:underline decoration-purple-800 underline-offset-8 decoration-[5px]">
                <p className={changeNavbar ? 'text-black px-2 py-4' : 'text-black px-2 py-3'}>Deportes</p>
              </Link>
            </li>

            <li className='hidden text-sm font-medium uppercase cursor-pointer md:flex h-max'>
              <Link to="/search/espectaculos" className="hover:underline decoration-purple-800 underline-offset-8 decoration-[5px]">
                <p className={changeNavbar ? 'text-black px-2 py-4' : 'text-black px-2 py-3'}>Espectáculos</p>
              </Link>
            </li>

            <li className='hidden text-sm font-medium uppercase cursor-pointer md:flex h-max'>
              <Link to="/search/tecnologia" className="hover:underline decoration-purple-800 underline-offset-8 decoration-[5px]">
                <p className={changeNavbar ? 'text-black px-2 py-4' : 'text-black px-2 py-3'}>Tecnología</p>
              </Link>
            </li>

            <li className='hidden text-sm font-medium uppercase cursor-pointer md:flex h-max'>
              <Link to="/search/energia" className="hover:underline decoration-purple-800 underline-offset-8 decoration-[5px]">
                <p className={changeNavbar ? 'text-black px-2 py-4' : 'text-black px-2 py-3'}>Energía</p>
              </Link>
            </li>

            {/* <li className='hidden text-sm font-medium uppercase cursor-pointer md:flex h-max'>
              <Link to="/Anticipacion-politica/" className='relative uppercase hover:underline decoration-purple-800 underline-offset-8 decoration-[5px]'>
                <p className={changeNavbar ? 'text-black px-2 py-6' : 'text-black px-2 py-3'}>Anticipación Política</p>
              </Link>
            </li> */}

            <li className='hidden text-sm font-medium uppercase cursor-pointer md:flex h-max'>
              <Link to="/Inmobiliarias" className='relative uppercase hover:underline decoration-purple-800 underline-offset-8 decoration-[5px]'>
                <p className={changeNavbar ? 'text-black px-2 py-6' : 'text-black px-2 py-3'}>Inmobiliarias</p>
              </Link>
            </li>

            {/* <li className='hidden text-sm font-medium md:flex h-max'>
              <button className={changeNavbar ?  'text-black px-2 py-6 uppercase' : 'hover:underline decoration-purple-800 underline-offset-8 decoration-[5px] text-black px-2 py-3 uppercase'}>más</button>
            </li>

            <ul id="submenu">
                <li className='hidden'>
                  <Link to="/Policiales">Policiales</Link>
                </li>
            </ul> */}


            <li className={changeNavbar ? 'cursor-pointer font-medium text-sm text-center relative top-2 w-max px-2 text-black h-max py-3' : 'cursor-pointer font-medium text-sm text-center relative top-2 w-max px-2 text-black h-max py-2'}>
                {/* <Link to="/clima" className='text-white'> */}
                

                { (values) ?

                  (window.location.href.includes('/search/') ? (
                    
                    <div className='flex'>
                    <img className="h-8 bottom-1.5 relative" src={Icons(icons)} alt={'Icono-Clima'}/>
                    <p className=' w-max'>{values.name} 

                      <i className='ml-1 not-italic text-1xl'>
                        {values.main.temp.toFixed(0)}
                      </i>

                      <i className='ml-1 text-xs not-italic'>
                      &#8451;
                      </i>

                      </p>
                  </div>

                  )

                  :
                  <div className='flex'><img className="h-8 bottom-1.5 relative" src={Icons(icons)} alt={'Icono-Clima'}/>
                                       <p className=' w-max'>{values.name} 

                      <i className='ml-1 not-italic text-1xl'>
                        {values.main.temp.toFixed(0)}
                      </i>

                      <i className='relative text-xs not-italic left-1'>
                      &#8451;
                      </i>

                      </p>
                  </div>

                  )


                : <Loader/>}
                {/* </Link> */}
              </li>

            <li className={changeNavbar ? 'w-max flex px-2 py-4'  : 'text-black px-2 py-3 relative h-max'}>
              <div className='h-100'>
            <Link to="/account/login" className="hover:text-purple-500">
                  {userInfo ? 
                    <p className='m-auto cursor-pointer hover:text-purple-500 text-md'>
                      <FontAwesomeIcon icon={faUser} className="text-sm cursor-pointer "/>
                    </p>
                  :
                  <>
                    <FontAwesomeIcon icon={faUser} className="pr-1 cursor-pointer text-md"/>
                  </>}
            </Link>
              </div>
                </li>

              <li className="flex text-black w-max h-max">
            <div>
                <button className={changeNavbar ? 'hover:text-purple-500 px-2 py-4' : 'text-black px-2 py-3'} onClick={handleSearch} type="button" name="searchBtn">
                  <FontAwesomeIcon icon={faSearch} className="cursor-pointer text-md"/>
                  <p className='hidden'>Busqueda</p>
                </button>
            </div>
              </li>

          </ul>
        </div>
      </nav>
      </div>
    </section>
  </header>
  {search === true ? 

  <section className='absolute  backdrop-blur-sm backdrop-brightness-75 bg-black/60 w-100 h-[100vh] top-0 z-10 flex justify-center'>
    <Search search={search}/>
  </section>
      :
  <section className='hidden  backdrop-blur-sm backdrop-brightness-75 bg-black/60 w-100 h-[100vh] top-0 z-10 justify-center'>
    <Search search={search} setter={setSearch}/>
  </section> }
                  </section>
  )
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight }
}
