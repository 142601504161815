import { faChevronUp, faHome, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
// import { Helmet } from "react-helmet";
import { useMediaQuery } from 'react-responsive';
import Header from "../../components/Home/HeaderTest.jsx";
import axios from "axios";
import Banner from './../../assets/data/banner.gif';
import Publicidades from "../../components/Ads/Publicidades";
import Footer from "../../components/Footer/Footer.jsx";
import OpenGraph from "../../components/OpenGraph/OpenGraph.jsx";

import banner from "./../../assets/Calf/2025/BannerPauta.gif";

const Category = () => {
  const { category } = useParams();
  const output = category.replace(/^\w/, c => c.toUpperCase());

  const [showButton, setShowButton] = useState(false);

  const [posts, setPosts] = useState([]);
  const [portada, setPortada] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0); // Offset para cargar más noticias
  const limit = 7; // Límite de noticias a cargar por vez

  const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""


  const fetchPosts = async (newOffset) => {
    try {
      setLoading(true);
      // const response = await axios.get(`${process.env.REACT_APP_GET_CATEGORIES}/buscar/${category}`);
      const response = await axios.get(`${process.env.REACT_APP_CONTENT}/buscar/${capitalize(category)}?offset=${newOffset}&limit=${limit}`);
      if ( offset === 0 ){
        setPosts(response.data);
        setPortada(response.data.pop());
      } else {
        setPosts(prevPosts => [...prevPosts, ...response.data]);
      }
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts(offset);
    setPortada(posts.pop());
    
    window.addEventListener("scroll", () => { if(window.pageYOffset > 300) { setShowButton(true); } else { setShowButton(false); } })
  }, [category, offset]);

  const loadMorePosts = () => {
    setOffset(prevOffset => prevOffset + limit);
  };

  //  const mapedPosts = offset[offset.length - 1].map((e) => e);
  //  const lastPost = mapedPosts;

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const title = 'Portal de Noticias';
  const description = 'Noticias de Neuquén, Río Negro, la Patagonia, Argentina y el mundo en tiempo real. Enteráte antes que nadie con el portal de noticias digital, VoxDiario. Los 40 Neuquén FM 92.3'
  const imageUrl = "https://opengraph.b-cdn.net/production/images/f91a8e89-688e-43b6-8fc4-95129f4b2660.png?token=ZCKldHAh3c2RYaq6W2ELCvYpw31n_SYm203sQqgD-9c&height=751&width=751&expires=33265553864";
  const type = 'website';

  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  let myMap = posts.map((e) => e);
  let myPost = myMap.pop();
  let myArr = [];
  myArr.push(myPost);

 return (
    <>
      { category ? (<Header category={category} />) : (<Header />)}
      
      

      <section id="container" className="min-h-screen">
        <Publicidades imagen={Banner} />
        <p className={isDesktop ? "px-8 text-sm text-neutral-400" : "text-sm text-neutral-400"}>
          <Link to="/" className="duration-200 hover:text-purple-700">
            <FontAwesomeIcon icon={faHome} /> Inicio
          </Link>
          / {output}
        </p>

        <h2 className={`text-purple-700 mt-1 text-4xl font-bold ${isDesktop ? 'px-6 text-md' : ''}`}>{output}</h2>
        <h3 className={`mt-1 text-xl ${isDesktop ? 'px-6 text-md' : 'pb-2'}`}>Las últimas noticias sobre {output}</h3>

        <section className="w-100">
        {  isDesktop ? ( <>
          <aside className="flex">
           { portada && ( <section className="w-3/4 m-4 mt-4"> 
              <div className="w-100 flex shadow rounded"> 
                <div className="w-2/3">
                <Link to={`${portada.Entry_Category}/${portada._id}`}>
                  <h2 className="text-2xl font-bold p-8">{portada.Entry_Title}</h2>
                </Link>
                </div>

                <div className="w-100"> 
                  <Link to={`${portada.Entry_Category}/${portada._id}`}>
                    <img src={portada.Entry_Featured_Image} alt={portada.Entry_Title} className="w-100 h-100 object-cover" />
                  </Link>
                </div>
              </div>
            </section>
           ) }
        
          <div className="w-auto mr-6">
            <img 
              src={banner}
              className="w-100 p-6 h-96 object-contain"
            />
          </div>
        </aside>
  <br />
  <hr />
  <br />
        </>) : null }
          
        
        
          {loading ? (
            <p>Cargando...</p>
          ) : error ? (
            <p>Ocurrió un error: {error}</p>
          ) : ( <section>
            <div className={`${isDesktop ? 'w-100 flex flex-wrap' : 'w-100'}`}>
              {posts.map((post, index) => (
                <div
                  key={index}
                  className={`${isDesktop ? 'm-6  pb-4 w-96 shadow-md hover:shadow-2xl' : 'h-auto '} mb-4 rounded-md`}
                >
                  <div className={isDesktop ? "w-auto" : "w-100 outline outline-1 outline-neutral-400 rounded-sm"}>
                    <a href={post.Entry_Category + '/' + post._id}>
                      <img
                        loading="lazy"
                        width="720"
                        className={isDesktop ? "h-48 object-cover" : "h-60 filter hover:brightness-105 object-cover w-100 saturate-105 delay-100 duration-150"}
                        src={post.Entry_Featured_Image}
                        alt={post.Entry_Title}
                      />
                    </a>
                  </div>

                  <div className={isDesktop ? 'w-auto py-2' : "w-full shadow-xl rounded"}>
                    
                    <span>
                      <a href={`${post.Entry_Category}/${post._id}`} className="duration-200 hover:text-purple-600">
                        <h2 className={`text-md ${isDesktop ? 'px-2 pb-1' : 'py-2'} w-auto font-bold font-sans`}>
                          {post.Entry_Title}
                        </h2>
                      </a>
                    </span>
                    <span className="">
                      <p className={isDesktop ? "text-xs p-2" : "text-xs pb-2"} id="card__preview-text">
                        {post.Entry_Resume}
                      </p>
                    </span>
                  </div>
                </div>
                
              ))}
              
              <div className="w-full">
                <span className="flex justify-center">
                  <button className="text-white font-bold rounded text-sm bg-[#604594] px-3 py-2 mb-4" onClick={loadMorePosts}>Más</button>
                </span>
              </div>

            </div>
            </section>
          )}
          
        </section>
      </section>
      
      <Footer />
      <button
      type="button"
      className={ showButton ? " inline-block w-10 m-1 h-9 animate-bouncing-button bg-purple-500 text-white font-xs hover:bg-purple-700 hover:shadow-lg focus:bg-purple-600 focus:shadow-lg focus:outline-none  active:bg-purple-800 active:shadow-lg transition duration-75 ease-in-out bottom-24 right-1 fixed"
    : "hidden w-9 h-9 bg-purple-500 text-white font-xs hover:bg-purple-700 hover:shadow-lg focus:bg-purple-600 focus:shadow-lg focus:outline-none  active:bg-purple-800 active:shadow-lg transition duration-75 ease-in-out bottom-4 right-1 fixed"}
      id="goTop"
      onClick={handleScroll}>

      <FontAwesomeIcon icon={faChevronUp} className="text-xs" />

    </button>      
    </>
  );
};

export default Category;
